@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
	.replace-2x {
		font-size: 1px;
	}
	.example {
		background-image: url(../images/example2x.png);
		-webkit-background-size:13px 13px;
		-moz-background-size:13px 13px;
		-o-background-size:13px 13px;
		background-size:13px 13px;
	}
}